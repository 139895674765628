              :root {
                 --none: none;
                 --primary: #ffac1f;
                 --secondary: #ff8b1f;
                 --white: #f5f5f5;
                 --black: #37393a;
                 --l-grey: #e0e2e3;
                 --grey: #9B9FA5;
                 --d-grey: #526064;
                 --vanta: #37393a;
                 --primary-gradient: linear-gradient(180deg, rgba(255, 172, 31, 1) 0%, rgba(255, 139, 31, 1) 100%);
             }
             
             .mobile__footer {
                 height: 100px;
                 width: 100%;
                 background: var(--d-grey);
                 position: relative;
                 bottom: 0%;
                 padding: 0% 10%;
                 display: flex;
                 flex-direction: row;
                 justify-content: space-between;
                 align-items: center;
                 img {
                     max-height: 20%;
                 }
                 span {
                     font-size: 12px;
                     color: var(--white);
                 }
             }