:root {
    --none: none;
    --primary: #ffac1f;
    --secondary: #ff8b1f;
    --white: #f5f5f5;
    --black: #37393a;
    --l-grey: #e0e2e3;
    --grey: #9B9FA5;
    --d-grey: #526064;
    --vanta: #37393a;
    --primary-gradient: linear-gradient(180deg, rgba(255, 172, 31, 1) 0%, rgba(255, 139, 31, 1) 100%);
}

@keyframes scrolling {
    0% {
        transform: translateX(-50%);
        left: -100%;
    }
    0.1% {
        opacity: 1,
    }
    100% {
        left: 200%;
        transform: translateX(-50%);
    }
}


/* Mobile */

@media only screen and (max-width: 959.9px) {
    .clients {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        overflow-x: hidden;
        .secondary__txt {
            font-size: 12px;
            color: var(--d-grey);
            height: 100%;
            width: 100%;
            padding: 10% 0%;
            background-color: transparent;
            z-index: -1;
        }
        .marquee__container--mobile {
            .image__container--desktop {
                img {
                    max-width: 9vw !important;
                    max-height: 10vw !important;
                }
            }
        }
    }
    .clients__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 30%;
        color: var(--primary);
        font-size: 24px !important;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 800;
        overflow-x: none;
        .image__container--mobile {
            height: 15%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            box-shadow: 0px 10px 20px #e0e2e361;
            margin: 5%;
            padding: 10%;
            img {
                max-height: 20px;
            }
        }
    }
    .clients__row.row-1,
    .clients__row.row-2 {
        width: 100%;
        height: 30%;
    }
}


/* Desktop */

@media only screen and (min-width: 960px) {
    .clients {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        overflow: hidden;
    }
    .clients__row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        color: var(--primary);
        font-size: 24px;
        letter-spacing: 2px;
        font-weight: 800;
        overflow-x: none;
        position: relative;
        height: 50%;
        &.main-head {
            font-size: 1.2vw;
            letter-spacing: 0.5em;
            font-weight: normal;
            text-align: center;
        }
        &.row-1,
        &.row-2 {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 80%;
            color: var(--primary);
            font-size: 24px;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: 800;
            overflow-x: none;
            position: relative;
            height: 50%;
            .image__container--desktop {
                width: 25% !important;
                height: 100%;
                margin: 1%;
                padding: 2%;
                img {
                    max-width: 6vw;
                }
            }
        }
        &.row-desc {
            font-size: 1vw;
            font-weight: normal;
            text-transform: initial;
            color: var(--d-grey);
            width: 50%;
        }
    }
    &.marquee {
        height: 100%;
        width: 100vw;
        .image__container--desktop {
            img {
                max-width: 5vw !important;
            }
        }
    }
}

.image__container--desktop {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 10px 20px #e0e2e361;
    height: 80%;
    padding: 1%;
    margin: 1%;
    img {
        max-width: 9vw !important;
        max-height: 5vw !important;
        transition: all 0.3s ease-in-out;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}