:root {
    --none: none;
    --primary: #ffac1f;
    --secondary: #ff8b1f;
    --white: #f5f5f5;
    --black: #37393a;
    --l-grey: #e0e2e3;
    --grey: #9B9FA5;
    --d-grey: #526064;
    --vanta: #37393a;
    --primary-gradient: linear-gradient(180deg, rgba(255, 172, 31, 1) 0%, rgba(255, 139, 31, 1) 100%);
}


/* Mobile */

@media only screen and (max-width: 959.9px) {
    .selected__name--container {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        margin-left: 10%;
        font-size: 1.8vh;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 800;
        color: var(--black);
    }
    .selected__title--container {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;
        margin-left: 10%;
        font-size: 1.2vh;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 800;
        color: var(--black);
    }
    .about {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--l-grey);
        height: 100%;
    }
    .about__row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 2% 5%;
        height: 20%;
    }
    .about__row>.member__container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .member__container-image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .member__container-image>img {
        max-width: 60%;
    }
    .member__container-name {
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: 2px;
        color: var(--d-grey);
        padding-right: 20px;
        margin-right: 20px;
        margin-left: 20px;
        width: 50%;
        text-align: left;
    }
    .member__container-title {
        font-size: 12px;
        color: var(--grey);
        color: var(--d-grey);
        text-align: right;
    }
    #btnOurCEO {
        position: absolute;
        transform: rotate(-90deg) translate(0%, -150%);
        height: 25px;
        width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        left: 0%;
        background: var( --primary-gradient);
        text-transform: uppercase;
        color: var( --white);
        font-weight: 800;
        font-size: 12px;
        letter-spacing: 3px;
        border-radius: 0px 0px 10px 10px;
        transition: all 0.3 ease-out;
        cursor: pointer;
    }
    #btnOurTeam {
        position: absolute;
        transform: rotate( -90deg) translate(0%, 150%);
        height: 25px;
        width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        right: 0%;
        background: var(--primary-gradient);
        text-transform: uppercase;
        color: var(--white);
        font-weight: 800;
        letter-spacing: 3px;
        font-size: 12px;
        border-radius: 10px 10px 0px 0px;
        transition: all 0.3 ease-out;
        cursor: pointer;
    }
    .about__ceo {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .about__ceo>.ceo__image-container {
        transform: scale(0.6);
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
    }
    .about__ceo>.ceo__image-container>img {
        border-radius: 20px;
        max-height: 150%;
        box-shadow: 50px 50px 20px #9b9fa538;
    }
    .ceo__name {
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: .8rem;
        color: var(--black);
    }
    .ceo__title {
        font-size: 16px;
        padding: 2.5% 0%;
        color: var(--d-grey);
        border-bottom: 1px solid var(--primary);
    }
    .ceo__desc {
        font-size: 14px;
        line-height: 30px;
        padding: 2.5% 0%;
        color: var(--d-grey);
    }
    .ceo__desc:nth-child(1) {
        padding-bottom: 0%;
    }
}


/* Desktop */

@media only screen and (min-width: 960px) {
    .about {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--l-grey);
    }
    .about__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0% 5%;
        height: 50%;
        background: #1f1f1f;
    }
    .about__row>.member__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 16.67%;
        height: 100%;
        position: relative;
        .member__container-image {
            height: 100%;
            width: 100%;
            img {
                height: 100%;
                width: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .member__container-name {
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: 2px;
        color: var(--primary);
        width: 80%;
        text-align: center;
        z-index: 2;
        font-size: 12px;
        letter-spacing: 5px;
    }
    .member__container-title {
        letter-spacing: 3px;
        font-size: 10px;
        color: var(--white);
        z-index: 2;
        padding-bottom: 5%;
        text-align: center;
        width: 80%;
    }
    #btnOurCEO {
        position: absolute;
        transform: rotate(-90deg) translate(0%, -100%);
        height: 50px;
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        left: 0%;
        background: var( --primary-gradient);
        text-transform: uppercase;
        color: var( --white);
        font-weight: 800;
        letter-spacing: 3px;
        border-radius: 0px 0px 10px 10px;
        transition: all 0.3 ease-out;
        cursor: pointer;
    }
    #btnOurTeam {
        position: absolute;
        transform: rotate(-90deg) translate(0%, 100%);
        height: 50px;
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        right: 0%;
        background: var( --primary-gradient);
        text-transform: uppercase;
        color: var( --white);
        font-weight: 800;
        letter-spacing: 3px;
        border-radius: 10px 10px 0px 0px;
        transition: all 0.3 ease-out;
        cursor: pointer;
    }
    .about__ceo {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 5%;
        width: 80%;
        background-color: var(--black);
        background-blend-mode: soft-light;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 120%;
        background-position-y: 200px;
        .ceo__text-container {
            width: 70%;
            padding: 5%;
        }
    }
    .about__ceo>.ceo__image-container {
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        img {
            position: absolute;
            transform: translate(0%, 0%);
            width: 90%;
            box-shadow: 0px 10px 20px #37393a85;
        }
    }
    .ceo__name {
        font-size: 30px;
        text-transform: uppercase;
        letter-spacing: 25px;
        font-weight: 600;
        color: var(--white);
        line-height: 35px;
    }
    .ceo__title {
        font-size: 14px;
        border-bottom: 1px solid var(--primary);
        padding-bottom: 2%;
        color: var(--white);
    }
    .ceo__desc {
        font-size: 14px;
        padding: 2% 0%;
        color: var(--white);
    }
    .ceo__desc:nth-child(1) {
        padding-bottom: 0%;
    }
}