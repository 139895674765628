:root {
    --none: none;
    --primary: #ffac1f;
    --secondary: #ff8b1f;
    --white: #f5f5f5;
    --black: #37393a;
    --l-grey: #e0e2e3;
    --grey: #9B9FA5;
    --d-grey: #526064;
    --vanta: #37393a;
    --primary-gradient: linear-gradient(180deg, rgba(255, 172, 31, 1) 0%, rgba(255, 139, 31, 1) 100%);
}


/* Mobile */

@media only screen and (max-width: 959.9px) {
    .careers {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .careers__toggle-button {
            height: 100%;
            width: 10%;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--d-grey);
            z-index: 10;
            transition: all 0.3s ease-in-out;
            background: var(--tertiary-gradient);
            text-align: center;
            &:hover {
                background-color: var(--d-grey);
                color: var(--white);
                cursor: pointer !important;
            }
        }
        .careers__toggle-button.next {
            right: 0%;
            top: 0%;
        }
        .careers__toggle-button.prev {
            left: 0%;
            top: 0%;
        }
    }
    .careers__container {
        position: relative;
        padding: 0% 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        .careers__container-job-posts-v2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .job-title__container {
                height: 10vh;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: var(--primary-gradient);
                color: var(--white);
                font-weight: 800;
                font-size: 14px;
                letter-spacing: 2px;
                text-transform: uppercase;
            }
            .job-title__index {
                height: 5vh;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .ind {
                    height: 5px;
                    width: 20%;
                    margin: 0% 1%;
                    border-radius: 20px;
                }
            }
            .job-description__container {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: center;
                color: var(--grey);
                line-height: 4vh;
                font-size: 16px;
            }
        }
    }
    .careers__container>img {
        position: absolute;
        left: 50%;
        z-index: -1;
        height: 100%;
        width: auto;
        transform: translate(-50%, 0%);
    }
    .careers__container-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .careers__container-title-h1 {
        font-size: 24px;
        letter-spacing: 3px;
        text-transform: uppercase;
        padding-bottom: 5%;
        margin-bottom: 0%;
        border-bottom: 1px solid var(--primary);
        color: var(--d-grey);
    }
    .careers__container-title-h2 {
        margin: 5% 0%;
        font-size: 18px;
        color: var(--grey);
        letter-spacing: 1px;
    }
    .careers__container-title-h2>.email {
        color: var(--primary) !important;
    }
    .careers__container-job-posts {
        height: 60%;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-x: scroll;
        width: 100%;
        background: var(--white);
        .job-title-indicators {
            width: 100%;
            height: 10%;
            background: red;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .careers__container-job-post-1,
        .careers__container-job-post-2 {
            border: 1px solid var(--l-grey);
            height: 100%;
            min-width: 100%;
            .job-title {
                color: white;
                letter-spacing: 1px;
                height: 20%;
                display: flex;
                flex-direction: row;
                font-weight: 800;
                align-items: center;
                background: var(--primary);
                padding: 5%;
            }
            .job-desc {
                font-size: 16px;
                line-height: 20px;
                color: var(--grey);
                padding: 10%;
                height: 80%;
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                line-height: 4vh;
            }
        }
    }
}


/* Desktop */

@media only screen and (min-width: 960px) {
    .careers {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .careers__container {
        position: relative;
        padding: 5%;
        height: 100%;
    }
    .careers__container>img {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        transform: translate(-50%, -50%);
    }
    .careers__container-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 50%;
        padding: 2.5%;
        img {
            height: 40%;
            position: absolute;
            left: 20%;
        }
    }
    .careers__container-title-h1 {
        font-size: 8em;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: var(--primary);
        width: 40%;
        background: linear-gradient( 270deg, #ffac1f40, rgba(255, 172, 31, 0));
        margin-right: 2%;
    }
    .careers__container-title-h2 {
        // font-size: 20px;
        // color: var(--d-grey);
        // text-transform: uppercase;
        // font-weight: 800;
        // letter-spacing: 1px;
        // width: 60%;    
        font-size: 20px;
        color: var(--d-grey);
        letter-spacing: 1px;
        width: 60%;
    }
    .careers__container-title-h2>.email {
        color: var(--primary) !important;
    }
    .careers__container-job-posts {
        height: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .careers__container-job-post-1,
    .careers__container-job-post-2 {
        padding: 2.5%;
        box-shadow: 0 10px 90px #37393a26;
        -webkit-backdrop-filter: blur(7px);
        backdrop-filter: blur(7px);
        border: 1px solid #ffffff14;
        margin: 1%;
        transition: all 0.8s cubic-bezier(0.65, 0.05, 0.36, 1);
        border: 1px solid var(--l-grey);
    }
    .job-title {
        font-size: 20px;
        color: var(--primary);
        letter-spacing: 1px;
    }
    .job-desc {
        font-size: 14px;
        line-height: 20px;
        color: var(--d-grey);
        padding-top: 2.5%;
        margin-top: 2.5%;
        border-top: 1px solid var(--primary);
    }
}