.tos-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    bottom: 0;
    background-color: var(--white);
    z-index: 32767;
    padding: 5%;
    overflow: scroll;
    h1 {
        color: var(--primary);
        margin: 1% 0%;
    }
    h2 {
        color: var(--d-grey);
        margin: 1% 0%;
    }
    div {
        color: var(--grey);
        margin: .5% 0%;
    }
    ul {
        color: var(--grey);
        padding: .5%;
        li {
            margin: 1% 0%;
            background: #fff;
            padding: 2%;
            border-radius: 10px;
            color: var(--grey);
            box-shadow: 0px 10px 10px #cfcbc436;
        }
        &.my-wheelbox-list {
            color: #246AFD;
            padding: .5%;
            li {
                margin: 1% 0%;
                background: #246AFD;
                padding: 3vw;
                border-radius: 10px;
                color: #fff;
                box-shadow: 0px 10px 10px #181A20;
            }
        }
    }
}

.tos-footer {
    margin: 5% 0% !important;
}

.underline {
    text-decoration: underline;
    font-weight: 800;
    color: var(--d-grey);
}