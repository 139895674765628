.error-page {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 999999;
    background-color: #fff;
    padding: 5%;
    background-image: url('../../assets/images/bnr-2.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-position: 25% 50%;
    h1 {
        color: var(--primary) !important;
        font-size: 10vw;
    }
    div {
        color: var(--grey) !important;
    }
}