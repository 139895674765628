                                    :root {
                                       --none: none;
                                       --primary: #ffac1f;
                                       --secondary: #ff8b1f;
                                       --white: #f5f5f5;
                                       --black: #37393a;
                                       --l-grey: #e0e2e3;
                                       --grey: #9B9FA5;
                                       --d-grey: #526064;
                                       --vanta: #37393a;
                                       --primary-gradient: linear-gradient(180deg, rgba(255, 172, 31, 1) 0%, rgba(255, 139, 31, 1) 100%);
                                   }
                                   
                                   body {
                                       overflow-x: hidden;
                                   }
                                   
                                   a {
                                       color: inherit;
                                       text-decoration: none;
                                   }
                                   
                                   a,
                                   a:hover,
                                   a:active,
                                   a:visited,
                                   a:focus {
                                       color: var(--white);
                                       text-decoration: none;
                                       outline: none;
                                   }
                                   
                                   ol,
                                   ul {
                                       list-style: none;
                                   }
                                   
                                   #tccLogo {
                                       max-width: 100%;
                                       max-height: 70%;
                                       display: flex;
                                   }
                                   
                                   .navbar {
                                       position: sticky;
                                       top: 0;
                                       z-index: 32767;
                                       width: 100%;
                                   }
                                   /* Mobile */
                                   
                                   @media only screen and (max-width: 959.9px) {
                                       .navbar-container {
                                           display: flex;
                                           flex-direction: row;
                                           justify-content: space-between;
                                           align-items: center;
                                           background: var(--primary-gradient);
                                           height: 50px;
                                           width: 100%;
                                           position: fixed;
                                       }
                                       .navbar-logo {
                                           height: 50%;
                                           display: flex;
                                           flex-direction: row;
                                           justify-content: center;
                                           align-items: center;
                                           padding: 0% 10%;
                                       }
                                       .menu-icon {
                                           padding: 0% 10%;
                                           cursor: pointer;
                                           color: var(--white);
                                       }
                                       .nav-menu {
                                           display: flex;
                                           flex-direction: column;
                                           justify-content: center;
                                           position: relative;
                                           top: 50px;
                                           left: 50%;
                                           height: calc(100% - 50px);
                                           transform: translateX(-150%);
                                           width: 100%;
                                           opacity: 0;
                                           transition: all 0.6s ease-in-out;
                                           background-color: var(--secondary);
                                           background-image: url('../../assets/images/bnr-2.png');
                                           background-position-y: 20%;
                                           background-position-x: 30%;
                                           position: fixed;
                                           background-repeat: no-repeat;
                                       }
                                       .nav-item,
                                       .nav-btn {
                                           padding-left: 10%;
                                           height: 10%;
                                           display: flex;
                                           flex-direction: row;
                                           align-items: center;
                                           padding-left: 10%;
                                           font-size: 5vh;
                                           font-weight: 800;
                                           text-transform: uppercase;
                                           cursor: pointer;
                                       }
                                       .nav-item,
                                       .nav-btn>a {
                                           text-decoration: none;
                                           color: var(--white);
                                       }
                                       .navbar.desktop {
                                           display: none;
                                           pointer-events: none;
                                       }
                                       .footer {
                                           position: absolute;
                                           bottom: -5%;
                                           left: 50%;
                                           transform: translate(-50%, -50%);
                                           width: 100%;
                                           display: flex;
                                           flex-direction: column;
                                           justify-content: center;
                                           padding: 10%;
                                           font-size: 10px;
                                           font-weight: normal;
                                           text-transform: initial;
                                       }
                                   }
                                   /* Desktop */
                                   
                                   @media only screen and (min-width: 960px) {
                                       .navbar.mobile {
                                           display: none;
                                           pointer-events: none;
                                       }
                                       .navbar-container {
                                           display: flex;
                                           flex-direction: row;
                                           justify-content: space-between;
                                           align-items: center;
                                           background: #fff;
                                           height: 50px;
                                           width: 100%;
                                           position: fixed;
                                           .nav-menu {
                                               width: 60%;
                                               height: 100%;
                                               display: flex;
                                               flex-direction: row;
                                               justify-content: space-around;
                                               align-items: center;
                                               .nav-item {
                                                   .nav-links {
                                                       text-decoration: none;
                                                       color: var(--black);
                                                       font-size: 12px;
                                                       letter-spacing: 3px;
                                                       text-transform: uppercase;
                                                       transition: all 0.3s ease-in-out;
                                                       cursor: pointer;
                                                       &.active {
                                                           color: var(--primary) !important;
                                                       }
                                                   }
                                               }
                                           }
                                           .navbar-logo {
                                               height: 100%;
                                               display: flex;
                                               flex-direction: row;
                                               justify-content: center;
                                               align-items: center;
                                               padding: 0% 2.5%;
                                           }
                                           .menu-icon {
                                               display: none;
                                               pointer-events: none;
                                           }
                                       }
                                   }