:root {
    --none: none;
    --primary: #ffac1f;
    --secondary: #ff8b1f;
    --white: #f5f5f5;
    --black: #37393a;
    --l-grey: #e0e2e3;
    --grey: #9B9FA5;
    --d-grey: #526064;
    --vanta: #37393a;
    --primary-gradient: linear-gradient(180deg, rgba(255, 172, 31, 1) 0%, rgba(255, 139, 31, 1) 100%);
}


/* Mobile */

@media only screen and (max-width: 959.9px) {
    .contact {
        background-color: var(--black);
        color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10%;
        height: calc(100% - 200px) !important;
        background-image: url('../../assets/images/contacts-background.svg');
        background-repeat: no-repeat;
        background-size: cover;
        .contact__col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
        .contact__col:nth-child(1) {
            height: 20%;
            .contact__row-header {
                font-size: 24px;
            }
            .contact__row-desc {
                font-size: 16px;
                margin: 2.5% 0%;
            }
        }
        .contact__col:nth-child(2) {
            height: 80%;
            justify-content: center;
            #contactForm {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 70%;
                input {
                    height: 40px;
                }
                textarea {
                    height: 50%;
                    resize: none;
                    margin-bottom: 2.5% !important;
                }
                input,
                textarea {
                    width: 100%;
                    padding: 2.5% 0%;
                    margin: 1% 0%;
                    background: none;
                    border: 1px solid var(--grey);
                    border-radius: 5px;
                    color: var(--white);
                    padding: 15px;
                }
                input:focus,
                textarea:focus,
                input:focus-visible,
                textarea:focus-visible {
                    background: none;
                    outline: none;
                    border: 1px solid var(--primary);
                }
                #btnSubmit {
                    width: 100%;
                    margin: 2.5% 0%;
                }
            }
        }
    }
}


/* Desktop */

@media only screen and (min-width: 960px) {
    .contact {
        background-color: var(--black);
        color: var(--white);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5%;
        background-image: url('../../assets/images/contacts-background.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .contact__col {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
    .contact__row-header {
        font-size: 20px;
        height: 10%;
    }
    .contact__row-desc {
        font-size: 14px;
        height: 10%;
    }
    .contact__row-details {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;
        width: 100%;
    }
    .contact__row-details>.details__col {
        width: 50%;
    }
    .address {
        height: 100%;
    }
    .address>.address-line {
        padding: 2.5% 0%;
        font-size: 14px;
    }
    .address-line.link {
        text-decoration: underline;
        color: var(--primary);
        font-weight: 800;
        cursor: pointer;
    }
    .contact__row-details>.contact-list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .contact-list>.email,
    .contact-list>.time {
        font-size: 14px;
        padding: 5% 0%;
    }
    .contact-list>.number {
        padding: 2.5% 0%;
        font-size: 14px;
    }
    .address>.email>span.orange {
        color: var(--primary);
        font-weight: 800;
    }
    #contactForm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
    #btnSubmit {
        margin: .5% 0%;
        height: 40px;
    }
    input {
        height: 40px;
    }
    textarea {
        height: 80px;
        resize: none;
    }
    input,
    textarea {
        background: none;
        border: 1px solid var(--grey);
        border-radius: 5px;
        margin: .5% 0%;
        color: var(--white);
        padding: 15px;
    }
    input:focus,
    textarea:focus,
    input:focus-visible,
    textarea:focus-visible {
        background: none;
        outline: none;
        border: 1px solid var(--primary);
    }
    input::placeholder,
    textarea::placeholder {
        color: var(--grey);
    }
}