:root {
    --none: none;
    --primary: #ffac1f;
    --secondary: #ff8b1f;
    --white: #f5f5f5;
    --black: #37393a;
    --l-grey: #e0e2e3;
    --grey: #9B9FA5;
    --d-grey: #526064;
    --vanta: #37393a;
    --primary-gradient: linear-gradient(180deg, rgba(255, 172, 31, 1) 0%, rgba(255, 139, 31, 1) 100%);
    --secondary-gradient: linear-gradient(180deg, rgba(82, 96, 100, 1) 0%, rgba(55, 57, 58, 1) 100%);
    --tertiary-gradient: linear-gradient(180deg, rgba(224, 226, 227, 1) 0%, rgba(155, 159, 165, 1) 100%);
}


/* Mobile */

@media only screen and (max-width: 959.9px) {
    .services {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: calc(100% - 100px) !important;
        width: 100% !important;
        padding: 10%;
    }
    .services__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid var(--l-grey);
        position: relative;
        height: 100%;
        width: 90%;
        .services__navigation {
            height: 40%;
            width: 100%;
            font-size: 1rem;
            padding: 10%;
            color: var(--grey);
            pointer-events: none;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .services__title {
                padding-bottom: 5%;
                text-transform: none;
                font-size: 5vw;
                color: var(--d-grey)
            }
            .services__desc {
                font-size: 16px;
                border-top: 1px solid var(--primary);
                color: var(--grey);
                padding-top: 5%;
            }
        }
        .services__selectors {
            height: 60%;
            width: 100%;
            bottom: 0%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: transparent;
        }
    }
    .serv-item {
        width: 100%;
        height: 100%;
        font-size: 16px;
        padding: 10%;
        color: var(--white);
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.6, 0.01, 0.5, 0.95);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        p {
            font-weight: 0;
            font-weight: 800;
        }
    }
    .services__selectors-after {
        display: none;
    }
    .serv-bg {
        width: 33.33%;
        height: 0%;
        font-size: 90px;
        writing-mode: vertical-lr;
        padding: 0;
        -webkit-text-stroke: 1px var(--l-grey);
        font-weight: 800;
        color: white;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 10px;
        position: relative;
        transition: all 0.8s cubic-bezier(0.6, 0.01, 0.5, 0.95);
        background-color: white;
    }
    .serv-item:nth-child(1) {
        background: var(--primary-gradient);
    }
    .serv-item:nth-child(2) {
        background: var(--secondary-gradient);
    }
    .serv-item:nth-child(3) {
        background: var(--tertiary-gradient);
    }
    .serv-bg.active {
        height: 100%;
    }
    .services__showcase {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100% !important;
        width: 100%;
        position: absolute;
        right: 0%;
        background: var(--l-grey);
        &.active {
            z-index: 4 !important;
        }
        .services__slide--containers {
            display: none;
            // display: flex;
            flex-direction: row;
            align-items: CENTER;
            justify-content: space-between;
            width: 100%;
            position: absolute;
            height: 10%;
            top: 45%;
            transform: translateY(-50%);
            padding: 0% 10%;
            z-index: 3;
            .services__slide {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                svg {
                    color: var(--grey);
                }
            }
        }
    }
    #btnBack {
        height: 50px;
        width: 100px;
        color: white;
        background-color: var(--black);
        z-index: 2;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .services__backroom-container,
    .services__mobile-container,
    .services__web-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
    }
    .services__container-hero {
        height: 40%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        background: var(--primary-gradient);
        cursor: pointer;
        .inner__slide {
            background-size: cover;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .inner__slide--container {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .inner__slide--item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    width: 100%;
                    position: relative;
                    img {
                        height: auto;
                        width: 80%;
                        object-fit: contain;
                    }
                }
            }
        }
    }
    .services__container-hero>img {
        max-height: 80%;
        max-width: 90%;
        position: absolute;
    }
    .services__container-selection {
        height: 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10%;
        position: relative;
        background-color: var(--white);
        .services__selection--item {
            font-size: 24px;
            margin: 1% 0%;
            padding: 1% 0%;
            border-bottom: 1px solid var(--primary);
            color: var(--grey);
            &.web {
                color: var(--secondary);
                border-bottom: 1px solid var(--secondary);
            }
        }
        .services__selection--desc {
            transition: all 0.8s cubic-bezier(0.6, 0.01, 0.5, 0.95);
            font-size: 16px;
            padding-top: 5%;
            line-height: 5vw;
            color: var(--grey);
        }
        .services__selection {
            width: 100%;
            height: 100%;
            padding: 10%;
            transition: all 0.8s cubic-bezier(0.6, 0.01, 0.5, 0.95);
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: absolute;
            opacity: 0;
            z-index: 0;
            .services__selection-title {
                font-size: 5vw;
                padding-bottom: 5%;
                border-bottom: 1px solid var(--primary);
                color: var(--d-grey);
                transition: all 0.8s cubic-bezier(0.6, 0.01, 0.5, 0.95);
            }
        }
    }
    .mobile__slide-indicator {
        height: 10%;
        width: 100%;
        background-color: var(--white);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .ind {
            height: 5px;
            width: 5px;
            background-color: var(--grey);
            border-radius: 5vw;
            transition: all 0.8s cubic-bezier(0.6, 0.01, 0.5, 0.95);
            opacity: .5;
            margin: 0% 2.5%;
            &.active {
                height: 10px;
                width: 10px;
                opacity: 1;
            }
        }
    }
    .services__selection.active {
        cursor: auto;
        opacity: 1;
        z-index: 2;
    }
    .services__container-mobile-bg {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .services__container-mobile-logo {
        height: 100px;
        position: absolute;
        width: 50%;
        z-index: 2;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transform-origin: center !important;
        margin-bottom: 25%;
    }
    .services__container-web-bg {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
    }
}


/* Desktop */

@media only screen and (min-width: 960px) {
    .services {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .services__container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--l-grey);
        position: relative;
    }
    .services__navigation {
        width: 25%;
        height: 100%;
        font-size: 1rem;
        padding: 2%;
        color: var(--grey);
        text-transform: uppercase;
        pointer-events: none;
        letter-spacing: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        .services__indicator {
            font-size: 5em;
            position: absolute;
            color: var(--primary);
            top: 10%;
        }
    }
    .services__title {
        font-weight: 800;
    }
    .services__desc {
        font-size: 12px;
        letter-spacing: 2px;
        padding-top: 25px;
        border-top: 1px solid var(--primary);
        color: var(--grey);
    }
    .services__selectors {
        height: 100%;
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: transparent;
    }
    .serv-item {
        border-left: 1px solid var(--l-grey);
        width: 33.33%;
        height: 100%;
        font-size: 10vh;
        writing-mode: vertical-lr;
        padding: 2%;
        -webkit-text-stroke: 1px var(--grey);
        font-weight: 800;
        color: transparent;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 10px;
        transition: all 0.3s cubic-bezier(0.6, 0.01, 0.5, 0.95);
        &:nth-child(1) {
            &:hover {
                -webkit-text-stroke: 0px var(--grey);
                color: var(--white);
                background-color: var(--primary);
            }
        }
        &:nth-child(2) {
            &:hover {
                -webkit-text-stroke: 0px var(--grey);
                color: var(--white);
                background-color: var(--secondary);
            }
        }
        &:nth-child(3) {
            &:hover {
                -webkit-text-stroke: 0px var(--grey);
                color: var(--white);
                background-color: var(--grey);
            }
        }
    }
    .services__selectors-after {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;
        position: absolute;
        top: 0;
        right: 0%;
        z-index: 0;
        background-color: white;
    }
    .serv-bg {
        width: 33.33%;
        height: 0%;
        font-size: 10vh;
        writing-mode: vertical-lr;
        padding: 0;
        -webkit-text-stroke: 1px var(--l-grey);
        font-weight: 800;
        color: white;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 10px;
        position: relative;
        transition: all 0.8s cubic-bezier(0.6, 0.01, 0.5, 0.95);
        background-color: white;
    }
    .serv-bg:nth-child(1) {
        background-color: var(--primary);
    }
    .serv-bg:nth-child(2) {
        background-color: var(--secondary);
    }
    .serv-bg:nth-child(3) {
        background-color: var(--d-grey);
    }
    .serv-bg.active {
        height: 100%;
    }
    .services__showcase {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100% !important;
        width: 76%;
        position: absolute;
        right: 0%;
        background: var(--l-grey);
        z-index: 3;
        &.active {
            z-index: 10 !important;
        }
        .container__slide--ind {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 10%;
            width: 100%;
            position: absolute;
            top: 0%;
            white-space: nowrap;
            overflow: hidden;
            z-index: 2;
            &.web {
                .slide--indicator {
                    background-color: var(--d-grey) !important;
                }
            }
            .slide--indicator {
                height: 5px;
                width: 5px;
                border-radius: 10px;
                margin: 0% 1%;
                background-color: var(--white);
            }
        }
        .services__slide {
            height: 100%;
            width: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: #52606438;
            color: var(--white);
            transition: all 0.3s ease-in-out;
            position: absolute;
            z-index: 2;
            &.prev {
                left: 0%
            }
            &.next {
                right: 0%;
            }
            &:hover {
                background-color: var(--d-grey);
            }
        }
        .services__counter {
            height: 5px;
            width: 100%;
            position: absolute;
            bottom: 0%;
            z-index: 2;
            background-color: var(--d-grey);
            .progress--bar {
                height: 100%;
                width: 0%;
                background: var(--primary-gradient);
                transition: all 0.5s ease-in-out;
            }
        }
    }
    #btnBack {
        height: 50px;
        width: 100px;
        color: white;
        background-color: var(--black);
        z-index: 2;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .services__backroom-container,
    .services__mobile-container,
    .services__web-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
    }
    .services__mobile-container.new {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        position: absolute !important;
        height: 100% !important;
        width: 100% !important;
        ._serv__text--cont {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 50%;
            width: calc(100% - 200px);
            z-index: 999;
            ._serv__text--inner {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 10%;
                color: var(--white);
                ._serv__text--logo {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                ._serv__text--title {
                    margin: 2% 0%;
                    font-size: 1.3em;
                }
            }
        }
        ._serv__img--index {
            height: 100%;
            width: calc(100% - 200px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            right: 100px;
            .services__container-hero {
                height: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                background: transparent !important;
                z-index: 1;
                overflow: hidden;
                img {
                    width: auto;
                    position: absolute;
                    bottom: -5%;
                }
            }
        }
    }
    .services__container-hero {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        background: var(--white);
        z-index: 1;
        .inner__slide {
            height: 100%;
            width: 100%;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .inner__slide--container {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .inner__slide--item {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    .bg-accent {
                        clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
                        background: var(--primary-gradient);
                        height: 100px;
                        width: 30px;
                        position: absolute;
                    }
                    img {
                        border-radius: 20px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -65%);
                        max-height: 80%;
                    }
                }
                .inner__slide--selections {
                    display: none;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 40%;
                    position: relative;
                    background: var(--primary);
                    .inner__slide--list {
                        transition: all 0.8s ease-in-out;
                        opacity: 0;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        z-index: 0;
                        img {
                            max-height: 100%;
                            max-width: 33.33%;
                            transform: scale(0.6);
                            border-radius: 20px;
                            border: 3px solid var(--white);
                            cursor: pointer;
                            box-shadow: 0px 10px 20px #37393a0f;
                        }
                        &.active {
                            opacity: 1;
                            z-index: 2;
                        }
                    }
                }
            }
        }
    }
    .services__container-hero>img {
        max-height: 85%;
        max-width: 90%;
        position: absolute;
    }
    .services__container-selection {
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0% 20%;
        bottom: 0%;
        color: var(--white);
        width: 100%;
        position: absolute;
        z-index: 1;
        .services__selection--item {
            font-size: 24px;
            margin: 1% 0%;
            padding: 1% 0%;
            border-bottom: 1px solid var(--white);
            &.web {
                color: var(--secondary);
                border-bottom: 1px solid var(--secondary);
            }
        }
        .services__selection--desc {
            font-size: 16px;
            &.web {
                color: var(--secondary);
            }
        }
    }
    .services__selection {
        width: 33.33%;
        height: 100%;
        padding: 2%;
        transition: all 0.8s cubic-bezier(0.6, 0.01, 0.5, 0.95);
        cursor: pointer;
    }
    .services__selection.active {
        cursor: auto;
        pointer-events: none;
    }
    .services__selection.active {
        background-color: var(--grey);
    }
    .services__selection.active>.services__selection-title,
    .services__selection.active>.services__selection-desc {
        color: var(--white);
    }
    .services__selection-title {
        font-size: 12px;
        letter-spacing: 2px;
        padding-top: 25px;
        text-transform: uppercase;
        color: var(--d-grey);
        transition: all 0.8s cubic-bezier(0.6, 0.01, 0.5, 0.95);
        font-weight: 800;
    }
    .services__selection-desc {
        transition: all 0.8s cubic-bezier(0.6, 0.01, 0.5, 0.95);
        font-size: 11px;
        letter-spacing: 2px;
        padding-top: 25px;
        color: var(--grey);
    }
    .services__container-mobile-bg {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
    }
    .services__container-mobile-logo {
        height: 100px;
        width: 200px;
        z-index: 2;
        background-size: contain;
        background-repeat: no-repeat;
        top: 50%;
    }
    .services__container-web-bg {
        height: 50%;
        width: 50%;
        background-repeat: no-repeat;
        background-position: 50%;
        position: absolute;
        top: 15%;
        background-size: contain;
    }
}

#tos-link {
    text-decoration: underline;
    &.wb {
        color: #fff;
    }
    &.hlk {
        color: #459373;
    }
}