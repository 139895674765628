@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap');
:root {
    --none: none;
    --primary: #ffac1f;
    --secondary: #ff8b1f;
    --white: #f5f5f5;
    --black: #37393a;
    --l-grey: #e0e2e3;
    --grey: #9B9FA5;
    --d-grey: #526064;
    --vanta: #37393a;
    --primary-gradient: linear-gradient(180deg, rgba(255, 172, 31, 1) 0%, rgba(255, 139, 31, 1) 100%);
}

html,
body {
    height: 100%;
    width: 100%;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Hind Siliguri', sans-serif;
}


/* Mobile */

@media only screen and (max-width: 959.9px) {
    .App {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
    }
}


/* Desktop */

@media only screen and (min-width: 960px) {
    .App {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: static;
        overflow-x: hidden;
    }
}


/* width */

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}


/* Track */

 ::-webkit-scrollbar-track {
    background: var(--grey);
    border-radius: 10px;
    z-index: 9999;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: var(--primary-gradient);
    border-radius: 10px;
    z-index: 10000;
}