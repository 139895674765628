:root {
    --none: none;
    --primary: #ffac1f;
    --secondary: #ff8b1f;
    --white: #f5f5f5;
    --black: #37393a;
    --l-grey: #e0e2e3;
    --grey: #9B9FA5;
    --d-grey: #526064;
    --vanta: #37393a;
    --primary-gradient: linear-gradient(180deg, rgba(255, 172, 31, 1) 0%, rgba(255, 139, 31, 1) 100%);
    --secondary-gradient: linear-gradient(180deg, rgba(82, 96, 100, 1) 0%, rgba(55, 57, 58, 1) 100%);
    --tertiary-gradient: linear-gradient(180deg, rgba(224, 226, 227, 1) 0%, rgba(155, 159, 165, 1) 100%);
    --gradient-transparent: linear-gradient(180deg, rgba(255, 172, 31, 1) 0%, rgba(255, 172, 31, 0) 100%);
}


/* Mobile */

@media only screen and (max-width: 959.9px) {
    .banner {
        z-index: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10%;
        color: var(--white);
        background-image: url(../../assets/images/bg--web.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        .hero-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .main-hero-img {
                left: 50%;
                opacity: 1;
                z-index: -10;
                width: 60%;
            }
        }
        .message__container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 10% 0%;
            &:before {
                content: '';
                height: 5px;
                width: 20%;
                background: var(--primary);
                display: block;
                margin-bottom: 12.5%;
            }
        }
    }
    .backdrop-image {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        opacity: .5;
        z-index: -2;
        width: 150%;
        pointer-events: none;
    }
    .banner-row {
        overflow: hidden;
        display: flex;
        align-items: center;
        width: 100%;
        &.message {
            font-size: 4vw;
            width: 100%;
            padding: 7.5% 0%;
        }
    }
    .row-title,
    .row-letter {
        font-size: 5vw;
        position: relative;
        display: inline-block;
        white-space: nowrap;
    }
    .row-letter.space {
        margin: 2%;
    }
    .row-col {
        display: flex;
        align-content: center;
        justify-content: center;
    }
    .scroll {
        height: 160px;
        width: 160px;
        border-radius: 100%;
        background: var(--white);
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        left: 160px;
    }
}


/* Desktop */

@media only screen and (min-width: 960px) {
    .banner {
        z-index: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5%;
        color: var(--d-grey);
        // background-color: var(--white);
        background-image: url(../../assets/images/bg--web.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        overflow: hidden;
        .backdrop-image {
            // position: absolute;
            // right: -5vw;
            // top: 50%;
            // opacity: 1;
            // z-index: -1;
            // transform: translate(25vw, -50%);
            position: absolute;
            right: 30%;
            height: auto;
            top: 50%;
            opacity: 1;
            z-index: -1;
            transform: translate(25vw, -50%);
            width: 30%;
        }
        .message__container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            .logo__container {
                padding: 6.5% 0%;
            }
            .h1__container {
                width: 80%;
                display: flex;
                flex-direction: column;
                padding: 5%;
                justify-content: center;
                // background: var(--gradient-transparent);
                position: relative;
                margin-bottom: 5%;
                // &:after {
                //     content: '';
                //     height: 50px;
                //     width: 400px;
                //     background: linear-gradient(90deg, rgba(255, 172, 31, 1) 0%, rgba(255, 172, 31, 0) 100%);
                //     display: block;
                //     position: absolute;
                //     top: 80%;
                //     left: 90%;
                //     transform: translate(-50%, -50%);
                // }
                // &:before {
                //     content: '';
                //     height: 50px;
                //     width: 300px;
                //     background: linear-gradient(90deg, rgba(155, 159, 165, 1) 0%, rgba(155, 159, 165, 0) 100%);
                //     display: block;
                //     position: absolute;
                //     top: 85%;
                //     left: 75%;
                //     transform: translate(-50%, -50%);
                // }
            }
        }
    }
    .banner-row {
        overflow: hidden;
        display: flex;
        align-items: center;
        line-height: 3vw;
        .row-title {
            // color: var(--d-grey);
            color: var(--white);
        }
        &.center {
            .row-title {
                color: var(--primary);
                span {
                    font-weight: 400;
                }
            }
        }
        &.message {
            line-height: 30px;
            font-size: 25px
        }
    }
    .row-title,
    .row-letter {
        font-size: 3vw;
        font-weight: 300;
        position: relative;
        display: inline-block;
        white-space: nowrap;
    }
    .row-letter.space {
        margin: 1%;
    }
    .row-col {
        display: flex;
        align-content: center;
        justify-content: center;
    }
    .row .row-message {
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.6rem;
        width: 320px;
    }
    .scroll {
        height: 160px;
        width: 160px;
        border-radius: 100%;
        background: var(--white);
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        left: 160px;
    }
}

.button_link {
    transition: all 0.2s ease-in-out;

    &:hover {
        background: var(--primary);
        border: 1px solid var(--primary) !important;
    }
}