:root {
    --none: none;
    --primary: #ffac1f;
    --secondary: #ff8b1f;
    --white: #f5f5f5;
    --black: #37393a;
    --l-grey: #e0e2e3;
    --grey: #9B9FA5;
    --d-grey: #526064;
    --primary-gradient: linear-gradient(180deg, rgba(255, 172, 31, 1) 0%, rgba(255, 139, 31, 1) 100%);
}

.btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.btn--primary {
    background: var(--primary-gradient);
    color: var(--white);
}

.btn-outline {
    background-color: transparent;
    color: var(--primary);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 18px;
    width: 50%;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--mobile {
    text-align: center;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 24px;
    background-color: transparent;
    color: var(--white);
    padding: 14px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn-wide {
    padding: 12px 64px;
    font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover,
.btn-mobile:hover,
.btn--wide:hover {
    transition: all 0.3s ease-out;
    background: var(--white);
    color: var(--primary);
}

.primary {
    background-color: var(--primary);
    border: none;
}

.primary:hover {
    background-color: var(--secondary);
    border: none;
}

.black {
    background-color: var(--black);
    border: none;
}

.grey {
    background-color: var(--grey);
    border: none;
}

.white {
    background-color: var(--white);
    border: none;
}