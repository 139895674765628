.maps_modal_main {
    height: 100vh;
    width: 100vw;
    background-color: #1f1f1f2d;
    position: fixed;
    z-index: 32767;
    .maps_inner {
        height: 80%;
        width: 80%;
        z-index: 32767;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--white);
        border-radius: 20px;
        box-shadow: 0px 0px 20px #1f1f1f18;
        padding: 10px;
    }
}